.ant-layout-content {
  margin-top: 40px;
}
.result {
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  min-height: 100px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

body {
  background: #f5f5f5;
}

.header.ant-layout-header {
  color: #f5f5f5;
  text-align: center;
  font-weight: bold;
}